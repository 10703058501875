<template>
  <div id="question">
    <div class="question-top">
      <div
        class="question-top-font"
        style="font-size:14px;line-height:37px;font-weight:bold;color:#606266"
      >
        关键字:
      </div>
      <el-input
        v-model="keywords"
        placeholder="问卷标题"
        style="width:200px;margin-left:15px"
      ></el-input>
      <el-button
        type="primary"
        style="margin-left:15px;width:80px"
        @click="btnquery"
        >查询</el-button
      >
      <buttonPermissions :datas="'questionpush'">
        <el-button
          type="primary"
          style="margin-left:15px;width:80px"
          @click="handleToDetail(0)"
          >添加</el-button
        >
      </buttonPermissions>
    </div>
    <div style="margin-top:10px;overflow:auto">
      <el-table :data="tabledata" v-loading="loading">
        <el-table-column
          prop="Title"
          label="问卷标题"
          align="left"
          width="500"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="SubmitMemberCount"
          label="提交人数"
        ></el-table-column>
        <el-table-column prop="AddTime" label="创建时间"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="tabel-operation">
              <buttonPermissions :datas="'questionputin'">
                <div class="tabel-operation-font" @click="putin(scope)">
                  投放
                </div></buttonPermissions
              >
              <buttonPermissions :datas="'questionedit'">
                <div
                  class="tabel-operation-font"
                  @click="handleToDetail(scope.row.Id)"
                >
                  编辑
                </div>
              </buttonPermissions>
              <!-- <div class="tabel-operation-font" @click="show = true">
                更多
              </div> -->
              <el-dropdown trigger="click" v-show="moreshow">
                <span class="tabel-operation-font" v-show="moreshow">
                  更多
                </span>
                <div style="display:flex;flex-direction:column">
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      class="tabel-operation-font"
                      style="margin-right:0px"
                      @click.native="copy(scope.row.Id)"
                    >
                      <buttonPermissions :datas="'questioncopy'">
                        <div style="color:#409eff">
                        复制
                        </div>
                      </buttonPermissions>
                    </el-dropdown-item>
                    <el-dropdown-item
                      class="tabel-operation-font"
                      style="margin-right:0px;"
                      @click.native="questionresult(scope)"
                    >
                      <buttonPermissions :datas="'questionresultbtn'">
                        <div style="color:#409eff">
                        问卷结果
                        </div>
                      </buttonPermissions>
                    </el-dropdown-item>

                    <el-dropdown-item
                      class="tabel-operation-font"
                      style="margin-right:0px;color:rgb(255,0,0);width:100%"
                      @click.native="del(scope)"
                    >
                      <buttonPermissions :datas="'questiondel'">
                        <div style="color:rgb(245,108,108)">
                        删除
                        </div>
                      </buttonPermissions>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </div>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align:right;width:100%;margin:5px 0px;zIndex:-10">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 50, 100]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <batch-cods
      :weiCodeVisivble="weiCodeShow"
      :weiCodeData="weiCodeData"
      @cancel="weiCodeCancel"
    ></batch-cods>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import buttonPermissions from "@/components/buttonPermissions";
import {
  questionlist,
  deltestpaper,
  testpaperdetail,
} from "@/api/TurnTomySelf";
import batchCods from "@/components/bathCodes";
export default {
  components: {
    buttonPermissions,
    batchCods,
  },
  computed: {
    ...mapGetters(["routerList"]),
  },
  data() {
    return {
      keywords: "", //关键字
      index: "-1",
      show: false,
      loading: false,
      putinpage: false,
      tabledata: [],
      weiCodeShow: false, //投放
      weiCodeData: {},
      putlink: "", //投放链接

      moreshow: false, //更多按钮是否显示

      currentPage: 1, //当前页
      pagesize: 20, //每页条数
      total: null, //总条数
    };
  },
  created() {
    this.getlist();
  },
  beforeMount() {
    this.getRouterList();
  },
  methods: {
    //如果更多 内的权限都没有 则不显示更多
    getRouterList() {
      if (
        this.routerList.some((item) => {
          return (
            item == "questiondel" ||
            item == "questioncopy" ||
            item == "questionresultbtn"
          );
        })
      ) {
        this.moreshow = true;
      } else {
        this.moreshow = false;
      }
      // console.log(this.routerList);
    },
    //查询按钮
    btnquery() {
      //查询要把页面返回第一页
      this.currentPage = 1;
      this.getlist();
    },
    //投放
    putin(e) {
      // console.log(e.row.Id)
      this.weiCodeFun(e.row.Id);
    },
    
    // 投放
    weiCodeFun(ProductId) {
      this.weiCodeShow = true;
      this.weiCodeData = {
        Page: `pageA/pages/questionnaire/questionnaire`,
        Scene: `id=${ProductId}&referer=1`,
      };
    },
    weiCodeCancel(value) {
      this.weiCodeShow = value;
    },
    //复制
    copy(e) {
      console.log(e);
      // this.gettestpaperdetail(e);
      this.$router.push({
        name: "editQuestionnaire",
        query: {
          virtualId: e,
        },
      });
    },
    //复制获取当前问卷的内容并去除id并跳转
    async gettestpaperdetail(e) {
      this.loading = true;
      try {
        let id = await e;
        let data = {
          QuestionnaireId: id,
        };
        let result = await testpaperdetail(data);
        if (result.IsSuccess) {
          // console.log(result.Result);
          this.$router.push({
            name: "editQuestionnaire",
            query: {
              message: result.Result,
            },
          });
        }
      } finally {
        this.loading = false;
      }
    },
    //跳转问卷结果
    questionresult(e) {
      this.$router.push({
        name: "questionresult",
        query: {
          id: e.row.Id,
        },
      });
    },
    //删除
    del(e) {
      console.log(e.row);
      this.$confirm("是否确认删除该问卷?删除后不可恢复", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //删除操作
          this.todel(e.row);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //页面条数改变
    handleSizeChange(e) {
      // console.log("当前页面每页", e);
      //当页面每页条数从大变小时，当前的页码可能显示为上一个的页码
      // let a = Math.ceil(parseInt(this.total) / parseInt(e));
      // if (a < this.currentPage) {
      //   this.currentPage = a;
      // }
      //强制页码到第一页 老是有bug
      this.currentPage = 1
      this.pagesize = e;
      this.getlist();
    },
    //页码变化
    handleCurrentChange(e) {
      // console.log("页码", e);
      this.currentPage = e;
      this.getlist();
    },
    //获取问卷列表
    async getlist() {
      // console.log("获取问卷列表");
      this.loading = true;
      try {
        let data = {
          Keywords: this.keywords,
          Skip: (this.currentPage - 1) * this.pagesize,
          Take: this.pagesize,
        };
        let result = await questionlist(data);
        // console.log(result);
        if (result.IsSuccess) {
          this.total = result.Result.Total;
          this.tabledata = result.Result.Results;
        }
      } finally {
        this.loading = false;
      }
    },
    //删除接口
    async todel(e) {
      //这里需要等一会儿参数 不然会报错
      this.loading = true;
      try {
        let id = await e;
        let data = {
          QuestionnaireId: id.Id,
        };
        let result = await deltestpaper(data);
        // console.log(result);
        if (result.IsSuccess) {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.getlist();
        }
      } finally {
        this.loading = false;
      }
    },
    //添加、编辑
    handleToDetail(id) {
      // console.log(id);
      this.$router.push({
        name: "editQuestionnaire",
        query: {
          id: id || "",
        },
      });
    },
  },
};
</script>

<style>
#question {
  width: 100%;
  height: auto;
  border: 1px solid rgb(245, 245, 245);
  background: white;
}
.question-top {
  width: 100%;
  height: auto;
  /* border: 1px solid black; */
  padding-left: 15px;
  padding-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  flex-wrap: wrap;
}
.table-title {
  width: 100%;
  height: auto;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-align: left;
  /* border:1px solid black */
}
.tabel-operation {
  display: flex;
  flex-direction: row;
  justify-content: left;
}
.tabel-operation-font {
  font-size: 14px;
  color: #409eff;
  cursor: pointer;
  margin-right: 20px;
}
.el-tooltip {
  min-width: 0px !important;
  margin-left: 60px;
  z-index: 999;
}
.popover {
  width: 50px;
  height: auto;
  background: white;
  position: relative;
  left: 60px;
}
.putin-linkbox {
  width: 300px;
  height: 30px;
  border: 1px solid rgb(230, 230, 230);
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  border-radius: 5px;
}
.putin-link-font {
  font-size: 14px;
  line-height: 30px;
  background: rgb(230, 230, 230);
  width: 240px;
  height: 100%;
  word-break: normal;
  overflow: hidden;
  /* padding-left: 5px; */
}
.putin-link-copy {
  width: 60px;
  height: 100%;
  background: white;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
}
.putin-link-code {
  width: 200px;
  height: 200px;
  border: 1px solid black;
  margin: 20px auto 0px auto;
}
.putin-link-code-font {
  width: 100px;
  /* border:1px solid black; */
  font-size: 12px;
  color: rgb(78, 165, 255);
  margin: 20px auto 0px auto;
  text-align: center;
  cursor: pointer;
}
.table-font {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.el-tooltip__popper {
  max-width: 500px;
}
.el-tooltip {
  margin-left: 0px;
}
</style>
